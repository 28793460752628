/* importing my tailwind  */
@tailwind base;
@tailwind components;
@tailwind utilities;
/* background image */
body {
  background-image: url("../public/assets/images/desert.jpg");
  background-repeat: no-repeat;
  background-attachment: fixed;
}

span {
  color: white;
}

.image-wrapper {
  background-size: cover;
  background-position: center;
  height: 0;
  padding-bottom: 56.25%;
  transition: all 0.2s ease;
}

.image-wrapper:hover {
  height: 200px; /* or whatever height you want the image to be */
}
.card{
  margin-left: 37%;
  margin-bottom: 13.9rem;
}
.projectcontainer {
  display: flex;
  justify-content: space-between;
  margin-top: 5px;
  /* add flex-direction and align-items */
  flex-direction: row;
  align-items: center;
}

.white {
  background-color: white;
 margin-left:5%;
  border-radius: 10%;
}

.md:flex {
  display: flex;
}

.bg-gray-700 {
  flex: 1;
  
  
}
@media (max-width: 768px) {
  .md:flex {
    flex-direction: column;
  }
}

.imageg {
  /* add flex-grow */
  flex-grow: 1;
  width: 100%;
}
.bulb{
  margin-left:1rem;
  margin-top:.5rem;
}